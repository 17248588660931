import assert from 'assert'
import loadable from '@loadable/component'
import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import { ActionButton } from '../components/Ring/ActionButton'
import { MarriageRingFeatures } from '../components/Ring/Features'
import { Guide } from '../components/Ring/Guide'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import { numberWithCommas, getMaterialLabel, getMaterial2Label } from '../utils'
import type {
    MarriageringDetailQuery,
    ImageSharp,
} from '../../types/graphql-types'
import type { MarriageRing, Material, Design } from '../types/ring'
import type { PageProps, HeadProps } from 'gatsby'

const Movie = loadable(() => import('../components/Movie'), { ssr: false })

export const Head: React.FC<HeadProps<MarriageringDetailQuery>> = ({
    data,
}) => {
    const ring = convertToRing(data)
    return (
        <SEO
            title={ring.name}
            description={ring.description}
            page={'ring/' + ring.code}
        >
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'Product',
                    name: ring.name,
                    description: ring.description,
                    image: ring.images.map((image) => {
                        return (
                            data.site?.siteMetadata?.siteUrl +
                            image.gatsbyImageData.images.fallback.src
                        )
                    }),
                    offers: {
                        '@type': 'Offer',
                        price: ring.femalePrice,
                        priceCurrency: 'JPY',
                    },
                })}
            </script>
        </SEO>
    )
}

const MarriageRingPage: React.FC<PageProps<MarriageringDetailQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const ring = convertToRing(data)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: ring.name },
            ]}
        >
            <Grid container spacing={0} component="main">
                <Grid item sm={12} xs={12}>
                    <RingName>
                        <Txt>{ring.name}ペアリング</Txt>
                    </RingName>
                </Grid>
                <Grid item sm={1} xs={12} />
                <Grid item sm={5} xs={12}>
                    <RingImageWrapper>
                        <Carousel autoPlay={false} height="280px">
                            {ring.images.map((image, index) => {
                                const img = getImage(image.gatsbyImageData)
                                return (
                                    img && (
                                        <RingImage key={index}>
                                            <GatsbyImage image={img} alt="" />
                                        </RingImage>
                                    )
                                )
                            })}
                        </Carousel>
                    </RingImageWrapper>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <RingInfoWrapper theme={muiTheme}>
                        <RingPriceWrapper>
                            <RingPrice
                                materialLabel={getMaterialLabel(ring.material)}
                                femalePrice={ring.femalePrice}
                                malePrice={ring.malePrice}
                                pairPrice={ring.pairPrice}
                            />
                            {ring.femalePrice2 &&
                                ring.malePrice2 &&
                                ring.pairPrice2 && (
                                    <RingPrice
                                        materialLabel={getMaterial2Label(
                                            ring.material
                                        )}
                                        femalePrice={ring.femalePrice2}
                                        malePrice={ring.malePrice2}
                                        pairPrice={ring.pairPrice2}
                                    />
                                )}
                        </RingPriceWrapper>
                        <Row>
                            <Txt size="s">サイズ15号~</Txt>
                            <BoldTxt size="s" bold>
                                +5,000円
                            </BoldTxt>
                        </Row>
                        <Row>
                            <Txt size="s">通常納期</Txt>
                            <BoldTxt size="s" bold>
                                2週間前後
                            </BoldTxt>
                        </Row>
                        <Row>
                            <Txt size="s">特急発送</Txt>
                            <BoldTxt size="s" bold>
                                5~7日
                            </BoldTxt>
                            <Txt size="s">(応相談)</Txt>
                        </Row>
                    </RingInfoWrapper>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <RingDescription>
                        <Txt>{ring.description}</Txt>
                    </RingDescription>
                </Grid>
                <Grid item sm={3} xs={12} />
                <Grid item sm={6} xs={12} component="aside">
                    {ring.femalePrice2 && ring.malePrice2 && ring.pairPrice2 && (
                        <Link to="/material/">
                            <StaticImage
                                src="../images/marriagering/material.jpg"
                                alt="金種の説明"
                            />
                        </Link>
                    )}
                    <Movie />
                </Grid>
                <Grid item sm={12} xs={12} component="aside">
                    <MarriageRingFeatures />
                    <ActionButton ring={ring} />
                    <Guide cartParams={ring.cartParams} />
                </Grid>
            </Grid>
        </Layout>
    )
}

const RingPrice = ({
    materialLabel,
    femalePrice,
    malePrice,
    pairPrice,
}: {
    materialLabel?: string
    femalePrice: number
    malePrice: number
    pairPrice: number
}) => {
    return (
        <RingPriceColumn>
            <RingPriceRow>
                <Txt size="s" primary>
                    {materialLabel}
                </Txt>
            </RingPriceRow>
            <RingPriceRow>
                <Txt size="s">Lady&apos;s</Txt>
                <PriceTxt bold>{numberWithCommas(femalePrice)}</PriceTxt>
                <Txt size="xs">円</Txt>
            </RingPriceRow>
            <RingPriceRow>
                <Txt size="s">Men&apos;s</Txt>
                <PriceTxt bold>{numberWithCommas(malePrice)}</PriceTxt>
                <Txt size="xs">円</Txt>
            </RingPriceRow>
            <RingPriceRow>
                <Txt size="xs">(2本ペア</Txt>
                <PriceTxt size="s">{numberWithCommas(pairPrice)}</PriceTxt>
                <Txt size="xs">円)(税込)</Txt>
            </RingPriceRow>
        </RingPriceColumn>
    )
}

function convertToRing(data: MarriageringDetailQuery): MarriageRing {
    const { edges: imageEdges } = data.allFile
    const getImage = (filename: string) => {
        return imageEdges.find((edge) => {
            return edge.node.relativePath === filename
        })?.node?.childImageSharp
    }

    const ring = data.marriageringsJson
    assert(ring)
    assert(ring.code)
    assert(ring.name)
    assert(ring.design)
    assert(ring.material)
    assert(ring.femalePrice)
    assert(ring.malePrice)
    assert(ring.pairPrice)
    assert(ring.images)
    assert(ring.cartParams)
    const images = ring.images.map((image) => {
        assert(image)
        return getImage('rings/' + ring.code + '/' + image) as ImageSharp
    })

    return {
        code: ring.code,
        name: ring.name,
        description: ring.description || '',
        design: ring.design as Design,
        material: ring.material as Material,
        femalePrice: ring.femalePrice,
        malePrice: ring.malePrice,
        pairPrice: ring.pairPrice,
        images: images,
        cartParams: ring.cartParams,
        femalePrice2: ring.femalePrice2 ?? undefined,
        malePrice2: ring.malePrice2 ?? undefined,
        pairPrice2: ring.pairPrice2 ?? undefined,
    }
}

export const query = graphql`
    query MarriageringDetail($code: String, $imageDir: String) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        marriageringsJson(code: { eq: $code }) {
            code
            name
            description
            design
            material
            femalePrice
            malePrice
            pairPrice
            femalePrice2
            malePrice2
            pairPrice2
            images
            cartParams
        }
        allFile(filter: { relativePath: { glob: $imageDir } }) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            width: 340
                            height: 280
                            quality: 90
                            transformOptions: { fit: FILL }
                        )
                    }
                    relativePath
                }
            }
        }
    }
`

const RingName = styled('h1')({
    margin: '8px 0 8px',
    textAlign: 'center',
    lineHeight: 1,
})
const RingImageWrapper = styled('div')({
    height: '328px',
    padding: '8px',
})
const RingImage = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})
const RingInfoWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px 20px',
    [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
    },
}))
const RingDescription = styled('div')({
    padding: '0 20px 20px',
})
const RingPriceWrapper = styled('div')({
    display: 'flex',
    padding: '5px 0',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '300px',
})
const RingPriceColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
})
const RingPriceRow = styled('div')({
    display: 'flex',
    padding: '5px 0',
    justifyContent: 'start',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    lineHeight: '1',
})
const Row = styled('div')({
    display: 'flex',
    padding: '5px 0',
    justifyContent: 'center',
    flexWrap: 'wrap',
})
const PriceTxt = styled(Txt)({
    margin: '0 4px 0 8px',
    lineHeight: 1,
})
const BoldTxt = styled(Txt)({
    margin: '0 8px',
})

export default MarriageRingPage
